<template>
	<slot />
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { disableZoom } from '@/utils/index'

// 更改页面标题
useHead({
  title: '官箱自开'
})

onMounted(async () => {
	await nextTick()

	disableZoom()
})
</script>